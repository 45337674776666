import { useQuery } from '@tanstack/react-query';
import { useMoralis } from 'react-moralis';

import { IReactQueryStatus } from 'types/types';

import {
  IGetAudioNFTRequestParams,
  IGetAudioNFTResponse,
} from './interface/getAudioNFT';
import {
  GET_FEATURED_NFTS,
  IGetFeaturedNFTsRequestParams,
  IGetFeaturedNFTsResponse,
} from './interface/getFeaturedNFTs';
import {
  GET_MARKETPLACE_DETAIL,
  IGetMarketplaceDetailRequestParam,
  IMarketPlaceDetailResponse,
} from './interface/getMarketplaceDetail';
import {
  GET_TOP_COLLECTION,
  IGetTopCollectionRequestParams,
  IGetTopCollectionResponse,
} from './interface/getTopCollection';
import {
  GET_TOP_LIST_CREATORS,
  IGetTopListCreatorsRequestParams,
  IGetTopListCreatorsResponse,
} from './interface/getTopListCreators';
import {
  IGetVideoNFTRequestParams,
  IGetVideoNFTResponse,
} from './interface/getVideoNFT';

const useHomePageAPIs = () => {
  const { Moralis } = useMoralis();

  const useFetchData = ({
    variableToStore,
    cloudFunctionName,
    params,
    dependencies = [],
  }) => {
    return useQuery(
      [variableToStore, ...dependencies],

      async () => {
        return await Moralis.Cloud.run(cloudFunctionName, params);
      }
    );
  };

  const useGetAudioNFT = ({ size, shopAddress }: IGetAudioNFTRequestParams) => {
    const {
      data,
      status,
    }: { data: IGetAudioNFTResponse[]; status: IReactQueryStatus } =
      useFetchData({
        variableToStore: 'getAudioNFT',
        cloudFunctionName: 'getAudioNFT',
        params: { size, shopAddress },
        dependencies: [size],
      });
    return { data, status };
  };
  const useGetVideoNFT = ({ size, shopAddress }: IGetVideoNFTRequestParams) => {
    const {
      data,
      status,
    }: { data: IGetVideoNFTResponse[]; status: IReactQueryStatus } =
      useFetchData({
        variableToStore: 'getVideoNFT',
        cloudFunctionName: 'getVideoNFT',
        params: { size, shopAddress },
        dependencies: [size],
      });
    return { data, status };
  };

  const useGetTopCollection = ({
    shopAddress,
    size,
    day,
  }: IGetTopCollectionRequestParams) => {
    const {
      data,
      status,
    }: { data: IGetTopCollectionResponse[]; status: IReactQueryStatus } =
      useFetchData({
        variableToStore: 'TopCollections',
        cloudFunctionName: GET_TOP_COLLECTION,
        params: { shopAddress, size, day },
        dependencies: [size, day],
      });
    return { data, status };
  };

  const useGetTopListCreators = ({
    size,
    shopAddress,
  }: IGetTopListCreatorsRequestParams) => {
    const {
      data,
      status,
    }: { data: IGetTopListCreatorsResponse[]; status: IReactQueryStatus } =
      useFetchData({
        variableToStore: 'TopListCreators',
        cloudFunctionName: GET_TOP_LIST_CREATORS,
        params: { size, shopAddress },
        dependencies: [size],
      });
    return { data, status };
  };

  const useGetFeaturedNFTs = ({
    chainId,
    mediaFormat,
    minPrice,
    maxPrice,
    sort,
    shopAddress,
  }: IGetFeaturedNFTsRequestParams) => {
    const {
      data,
      status,
    }: { data: IGetFeaturedNFTsResponse[]; status: IReactQueryStatus } =
      useFetchData({
        variableToStore: 'FeaturedNFTs',
        cloudFunctionName: GET_FEATURED_NFTS,
        params: { chainId, mediaFormat, minPrice, maxPrice, sort, shopAddress },
        dependencies: [mediaFormat, minPrice, maxPrice, sort, chainId],
      });
    return { data, status };
  };

  const getMarketPlaceDetail = async ({
    subscriptionId,
  }: IGetMarketplaceDetailRequestParam) => {
    const marketPlaceDetail = await fetch(
      `${process.env.REACT_APP_MORALIS_SERVER_URL}/functions/${GET_MARKETPLACE_DETAIL}`,
      {
        method: 'POST',
        body: JSON.stringify({ subscriptionId }),
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );
    const detail = await marketPlaceDetail?.json();
    const modifiedResponse: IMarketPlaceDetailResponse = detail?.result;
    return modifiedResponse;
  };

  return {
    useGetAudioNFT,
    useGetVideoNFT,
    useGetTopCollection,
    useGetTopListCreators,
    useGetFeaturedNFTs,
    getMarketPlaceDetail,
  };
};
export default useHomePageAPIs;
