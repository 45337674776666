export const GET_MARKETPLACE_DETAIL = 'getMarketPlaceData';

export interface IGetMarketplaceDetailRequestParam {
  subscriptionId: string;
}

export interface IMarketPlaceDetailResponse {
  shopAddress: {
    chaidId: string;
    shopAddress: string;
  }[];
  status: 'Default' | 'active' | 'inActive';
  shop_name: string;
  shop_symbol: string;
  domain: string;
  createdAt: string;
  updatedAt: string;
  subscriptionId: string;
  shop_desc: string;
  shop_logo: string;
  objectId: string;
  __type: 'Object';
  className: 'Marketplace';
}
