export const GET_TOP_COLLECTION = 'getTopCollection';

export interface IGetTopCollectionRequestParams {
  shopAddress: string;
  size: number;
  day: number;
}

export interface IGetTopCollectionResponse {
  name: string;
  image: string;
  owner: string;
  chainId: string;
  contractAddress: string;
  nftImages: string[];
  objectId: string;
}
