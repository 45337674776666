export const GET_TOP_LIST_CREATORS = 'getTopListCreators';

export interface IGetTopListCreatorsRequestParams {
  shopAddress:string;
  size: number;
}

export interface IGetTopListCreatorsResponse {
  username: string;
  walletAddress: string;
  objectId: string;
  totalNFTs: number;
  shopAddress:string;
}
