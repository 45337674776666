import { IMediaFilterFormats, ISortFilter } from 'types/types';

export const GET_FEATURED_NFTS = 'getFeaturedNFTs';

export interface IGetFeaturedNFTsRequestParams {
  chainId: string;
  mediaFormat: IMediaFilterFormats[];
  minPrice: number;
  maxPrice: number;
  sort: ISortFilter;
  shopAddress: string;
}

export interface IGetFeaturedNFTsResponse {
  name: string;
  price: string;
  image: string;
  chainId: string;
  nftType: 'onChain' | 'gas_free' | 'onAuction';
  mediaFormat: string;
  numberOfCopies: number;
  nftContract: string;
  tokenId: string;
  objectId: string;
  shopAddress: string;
  owner: string;
}
